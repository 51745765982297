import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BackgroundImg = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "boat3.jpg" }) {
          id
          childImageSharp {
            sizes {
              src
            }
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <BackgroundImage
      //   backgroundcolor={`#000000`}
      style={{
        height: "100%",
        // backgroundSize: "",
        // backgroundPosition: "",
        // backgroundRepeat: "",
      }}
      fluid={data.image.childImageSharp.fluid}
    >
      <main>{children}</main>
    </BackgroundImage>
  )
}

export default BackgroundImg
